import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal , 
  DatePicker
} 
  from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../../environment/environment';
import { isEmpty, parseInt } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';
import dayjs from 'dayjs'
import moment from 'moment';

const baseUrl = environment.apiUrl;
const apiUrl = "/api/sales-target";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const formatDate = (date) => {
    var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    day = '01';
    if([year, month, day].join('-') === "1970-01-01"){
        return null;
    }else if([year, month, day].join('-') === "NaN-NaN-01"){
        return null;
    }
    else{
        return [year, month, day].join('-');
    }
}

var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

const MasterSalesTarget = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [inputTargetMonthFrom, setInputTargetMonthFrom] = useState();
  const [inputTargetMonthTo, setInputTargetMonthTo] = useState();
  const [inputTeam, setInputTeam] = useState(null);
  const [inputDivision, setInputDivision] = useState(null);

  const [csvDisabled, setCsvDisabled] = useState(true);
  const [divisionTeamDisabled, setDivisionTeamDisabled] = useState(true);
  const [divisionTeamDisabledAddModal, setDivisionTeamDisabledAddModal] = useState(true);

  //Combobox
  const [teamCombobox,setTeamCombobox] = useState([]);
  const [divisionCombobox,setDivisionCombobox] = useState([]);
  const [divisionTeamCombobox,setDivisionTeamCombobox] = useState([]);
  const [divisionTeamComboboxAddModal,setDivisionTeamComboboxAddModal] = useState([]);

  const [monthEditedValue , setMonthEditedValue] = useState("");
  const [teamEditedValue , setTeamEditedValue] = useState("");

  const [inputData, setInputData] = useState({target_month:"",
                                              division:"",
                                              team:"",
                                              target_revenue:"",
                                              remark:"",
                                              updated_by: user,
                                              // updated_timestamp:"",
  });

  useEffect(() => {
    getTeamCombobox();
    getDivisionCombobox();
    getDivisionTeamCombobox(inputDivision);
    getEditDataPermission();
    getDivisionTeamComboboxAddModal(inputData.division)
  },[inputDivision,inputData.division]);

 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showAddDataModal = () => {
    setIsModalOpen(true);
  };

   const handleOk = () => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };

    var updatedTimestamp = new Date()
    inputData.updated_timestamp = updatedTimestamp
   
    axios.post(baseUrl+apiUrl+`/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);
          const oldData = [...modiefiedData]

          response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

          const dataAdd = {
            record_id: response.data.record_id,
            target_month: moment(response.data.target_month).format('MMM-YYYY'),
            division: response.data.division,
            team: response.data.team,
            target_revenue: response.data.target_revenue, 
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);
          
          setInputData({target_month:"",
          division:"",
          team:"",
          target_revenue:"",
          remark:"",
          updated_by: user,
          updated_timestamp:"",
        })
        })
        .catch(function (error) {
          if(error.response.status === 401){
            Swal.fire("Token Expired", error.message, "error");
            sessionStorage.clear();
            window.location.href = "/";
            setLoading(false);
          }else{
            Swal.fire("Error", error.message, "error");
            setLoading(false);
          }
        });
        // setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({target_month:"",
        division:"",
        team:"",
        target_revenue:"",
        remark:"",
        updated_by: user,
        // updated_timestamp:"",
    })
  };

  const loadData = async () => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
                'target_month_from': formatDate(inputTargetMonthFrom),
                'target_month_to': formatDate(inputTargetMonthTo),
                'team': inputTeam,
                'division': inputDivision
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+apiUrl,config);

      for (var i=0; i< response.data.length; i++) {
        response.data[i].updated_timestamp = new Date(response.data[i].updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })
      }


      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);

    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  }
  
  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setInputTargetMonthFrom("");
    setInputTargetMonthTo();
    setInputTeam("");
    setInputDivision("");
    setCsvDisabled(true);
    setDivisionTeamDisabled(true);
    setInputData({
        target_month:"",
        division:"",
        team:"",
        target_revenue:"",
        remark:"",
        updated_by: user,
        updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();

        const newData = [...modiefiedData];

        const index = newData.findIndex((item) => key === item.key);

        var monthSplit = monthEditedValue.split('-')[0]; 
        var targetMonth;
        var yearSplit = monthEditedValue.split('-')[1];

        if(monthSplit === "Jan"){
          targetMonth = `${yearSplit}-01-01`;
        }else if(monthSplit === "Feb"){
          targetMonth = `${yearSplit}-02-01`;
        }else if(monthSplit === "Mar"){
          targetMonth = `${yearSplit}-03-01`;
        }else if(monthSplit === "Apr"){
          targetMonth = `${yearSplit}-04-01`;
        }else if(monthSplit === "May"){
          targetMonth = `${yearSplit}-05-01`;
        }else if(monthSplit === "Jun"){
          targetMonth = `${yearSplit}-06-01`;
        }else if(monthSplit === "Jul"){
          targetMonth = `${yearSplit}-07-01`;
        }else if(monthSplit === "Aug"){
          targetMonth = `${yearSplit}-08-01`;
        }else if(monthSplit === "Sep"){
          targetMonth = `${yearSplit}-09-01`;
        }else if(monthSplit === "Oct"){
          targetMonth = `${yearSplit}-10-01`;
        }else if(monthSplit === "Nov"){
          targetMonth = `${yearSplit}-11-01`;
        }else if(monthSplit === "Dec"){
          targetMonth = `${yearSplit}-12-01`;
        }
        
        if(teamEditedValue !== ''){
          row.team = teamEditedValue;
        }

        var updatedTimestamp = new Date();
        row.updated_timestamp = updatedTimestamp
        
        const editedData = {
          record_id: newData[index].record_id,
          target_month: targetMonth,
          division: row.division,
          team: row.team,
          target_revenue: parseInt(row.target_revenue.replace(/,/g,'')),
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp,
        }

        axios.put(baseUrl + apiUrl +`/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                target_month: moment(response.data.target_month).format('MMM-YYYY'),
                division: response.data.division,
                team: response.data.team,
                target_revenue: response.data.target_revenue, 
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
      }else{
        Swal.fire("Error", error.message, "error");
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        target_month: record.target_month ? dayjs(record.target_month,"MMM-YYY"):"",
        division: "",
        team: "",
        target_revenue: parseInt(record.target_revenue.replace(/,/g,'')),
        remark: "",
        updated_by: "",
        updated_timestamp: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = 
    gridData.map(({ body, ...item}) =>  
      (
        {
          ...item,
          key: item.record_id,
          target_month: item.target_month,
          division: item.division,
          team: item.team,
          target_revenue: parseFloat(item.target_revenue.replace(/,/g,'')).toLocaleString(),
          remark: item.remark,
          updated_by: item.updated_by,
          updated_timestamp: item.updated_timestamp,
          message: isEmpty(body) ? item.message : body,
        }
      )
    );  
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      width: 50,
      hidden: true,
    },
    {
      title: "Target Month",
      dataIndex: "target_month",
      align: "center",
      editTable: true,
      width: 70,
    },
    {
      title: "Division",
      dataIndex: "division",
      align: "center",
      editTable: true,
      width: 40,
    },
    {
        title: "Team",
        dataIndex: "team",
        align: "center",
        editTable: true,
        width: 40,
    },
    {
        title: "Target Revenue",
        dataIndex: "target_revenue",
        align: "center",
        editTable: true,
        width: 50,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      // editTable: true,
      width: 50,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      // editTable: true,
      width: 70,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: 'right',
          width: 100,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData||editable}>
                    <DeleteOutlined/>
                  </Button>
                
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{margin: 0}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();                
          })
          .catch(function (error) {
              if(error.response.status === 401){
                sessionStorage.clear();
                window.location.href = "/";
              }else{
                Swal.fire({
                  icon: 'error',
                title: 'An Error Occured!',
                showConfirmButton: false,
                timer: 1500
              })
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;
    return (
      <td {...restProps}>
          {editing  ? (
              dataIndex === "target_month" ? (
              <MonthPicker
                onChange={(time,timeString) => {
                    setMonthEditedValue(timeString)
                }}
                value={monthEditedValue === "" ? dayjs(record[dataIndex],"MMM-YYYY") 
                : dayjs(monthEditedValue,"MMM-YYYY")
                }
                format={"MMM-YYYY"}
              />
            ) : dataIndex === "division" ? (
              <Form.Item 
             name={dataIndex} 
             style={{margin: 0 }}
             rules={
              [
                {
                required: false,
                message: `Please input value ${title} field`,
              },
            ]}
            >
              <Input 
                list="divisionList"
                onClick={clear}
              />
             </Form.Item>
            )
            : dataIndex === "team" ? (
              <Form.Item 
                name={dataIndex}
                style={{margin: 0 }}
              >
                <>
                  <Input 
                    value={teamEditedValue !== '' ? teamEditedValue : record[dataIndex]}
                    list="teamList"
                    onClick={clear}
                    onChange={(e) => 
                      {
                        teamEditedValue === null ? setTeamEditedValue('') 
                        : 
                        setTeamEditedValue(e.target.value)
                      }
                    }
                  />
                  <datalist id="teamList">
                    {teamCombobox.map((o) => (
                      <option key={o}>{o}</option>
                    ))}
                </datalist>
                </>
            </Form.Item>
             ) 
            : dataIndex === "remark" ? (
              <Form.Item 
             name={dataIndex} 
             style={{margin: 0 }}
             rules={
              [
                {
                required: false,
                message: `Please input value ${title} field`,
              },
            ]}
            >
              {input}
             </Form.Item>
               )
               : dataIndex === "target_revenue" ? (
                <Form.Item 
               name={dataIndex} 
               style={{margin: 0 }}
               rules={
                [
                  {
                  required: false,
                  message: `Please input value ${title} field`,
                },
              ]}
              >
                <Input 
                value={parseInt(record[dataIndex].replace(/,/g,''))}
                />
               </Form.Item>
                 )   
            :  (
              <Form.Item 
             name={dataIndex} 
             style={{margin: 0 }}
             rules={
              [
                {
                required: true,
                message: `Please input value ${title} field`,
              },
            ]}
            >
              {input}
             </Form.Item>
            )
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
            'target_month_from': formatDate(inputTargetMonthFrom),
            'target_month_to': formatDate(inputTargetMonthTo),
            'team': inputTeam,
            'division': inputDivision,
            'timezone': tzName
        }
   }; 
    axios.get(baseUrl+apiUrl+"/csv", config)
    .then(response => {
            const csvData = new Blob(["\uFEFF" + response.data], { type: 'text/csv;' });
            FileSaver.saveAs(csvData, 'MASTER_SALES_TARGET.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
           }
        )
  }

  const getTeamCombobox = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      }; 
    const res = await axios.get(baseUrl+"/api/division/teamCombobox",config);
    const dt = res.data;
    setTeamCombobox(dt);

}
const getDivisionCombobox = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },

      }; 
    const res = await axios.get(baseUrl+"/api/division/divisionCombobox",config);
    const dt = res.data;
    setDivisionCombobox(dt);
    
}

const getDivisionTeamCombobox = async (inputDivision) => {
  setDivisionTeamDisabled(true);
  if(inputDivision !== null && inputDivision !== ''){
    setDivisionTeamDisabled(false);
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        params: {
          'division': inputDivision
        }
      }; 
    const res = await axios.get(baseUrl+"/api/division/divisionTeamCombobox",config);
    const dt = res.data;
    setDivisionTeamCombobox(dt);
  }else{
    setDivisionTeamCombobox([]);
    setDivisionTeamDisabled(true);
  }
}

const getDivisionTeamComboboxAddModal = async (inputDivision) => {
  setDivisionTeamDisabledAddModal(true);
  if(inputDivision !== null && inputDivision !== ''){
    setDivisionTeamDisabledAddModal(false);
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        params: {
          'division': inputDivision
        }
      }; 
    const res = await axios.get(baseUrl+"/api/division/divisionTeamCombobox",config);
    const dt = res.data;
    setDivisionTeamComboboxAddModal(dt);
  }else{
    setDivisionTeamComboboxAddModal([]);
    setDivisionTeamDisabledAddModal(true);
  }
}

//edit_data Permission
const [editData, setEditData] = useState(false);
const getEditDataPermission = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      }; 
    try{
      const response = await fetch(
        baseUrl+"/api/auth/get-permission",config
      ).then((response) => response.json()); 
      // update the state
      const editDataPermission = response.permission;
      for(let i = 0 ; i < editDataPermission.length ;  i++){
        if(editDataPermission[i].module_name === "Sales Target"){
            setEditData(editDataPermission[i].edit_data);
        }
      }
    }catch(error){
        sessionStorage.clear();
        window.location.href = "/";
  }
  }

  const clear = (event) => {
    event.target.value = "";
  };

  const { MonthPicker } = DatePicker

  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Sales Target</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft:'5px'}}>Target Month From: </span><br></br>
            <MonthPicker
                onChange={inputTargetMonthFrom => 
                        setInputTargetMonthFrom(inputTargetMonthFrom)
                } 
                format={"MMM-YYYY"}
                style={{width:'100%', marginLeft: '5px'}}
                value={inputTargetMonthFrom !== "" ? inputTargetMonthFrom : ""}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span>Target Month To: </span>
            <MonthPicker
                onChange={inputTargetMonthTo => 
                  setInputTargetMonthTo(inputTargetMonthTo)
                } 
                format={"MMM-YYYY"}
                value={inputTargetMonthTo !== "" ? inputTargetMonthTo : ""}
                style={{width:'100%'}}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
        <div>
            <span style={{margin:'5px'}}>Division: </span>
            <Input 
            style={{margin:'4px'}} 
            placeholder="" 
            value={inputDivision}
            onChange={(e) => {
                if(e.target.value === '' || e.target.value === null){
                  setDivisionTeamDisabled(true)
                  setInputDivision(e.target.value);
                  setInputTeam('');
                }else{
                  setInputDivision(e.target.value)
                }
              }
            }
            onClick={clear}
            list="divisionList"/>
            <datalist id="divisionList" >
              <option key="" label=" ">{""}</option>
              {divisionCombobox.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </datalist>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft:'1px'}}>Team: </span>
            <Input style={{marginTop:'3px'}} placeholder=""
            value={inputTeam}
            onChange={(e) => {
              e.target.value === "" ? setInputTeam(''):
              setInputTeam(e.target.value)
              // onChange(e.target)
            }}
            onClick={clear}
            disabled={divisionTeamDisabled}
            list="divisionTeamList"/>
            <datalist id="divisionTeamList">
              {divisionTeamCombobox.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </datalist>
            {false &&
              <>
              <Input list="teamList"/>
              <datalist id="teamList">
                {teamCombobox.map((o) => (
                  <option key={o}>{o}</option>
                ))}
              </datalist>
              </>
            }
          </div>
        </Col>
        
        <Col className="gutter-row" span={12}>
          <div>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Sales Target Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Target Month : </span>
                <MonthPicker 
                style={{ width: '100%'}}
                defaultValue={formatDate()}
                onChange={(e,monthStr) => {
                      setInputData({...inputData, target_month: formatDate(e)})
                  }
                }
                format={"MMM-YYYY"}
                />
                <span>Division : </span>
                <Input 
                    value={inputData.division}
                    onChange={e => setInputData({...inputData, division: e.target.value})}
                    list="divisionList"
                    onClick={clear}
                />
                <datalist id="divisionList">
                  {divisionCombobox.map((o) => (
                    <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Team : </span>
                <Input 
                    value={inputData.team}
                    onChange={e => setInputData({...inputData, team: e.target.value})}
                    list="teamList"
                    disabled={divisionTeamDisabledAddModal}
                    onClick={clear}
                />
                <datalist id="teamList">
                  {divisionTeamComboboxAddModal.map((o) => (
                    <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Target Revenue : </span>
                <Input value={inputData.target_revenue}
                    onChange={e => setInputData({...inputData, target_revenue: e.target.value})}/>
                <span>Remark : </span>
                <Input value={inputData.remark}
                    onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '300px' }}
            scroll={{
              x: 500,
              y: 280
            }}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default MasterSalesTarget