import '../../App.css'
import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal , DatePicker} from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../environment/environment';
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

const baseUrl = environment.apiUrl;
const apiUrl = "/api/incoming-delivery";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const formatTime = (timeStr) => {
  const timeString = timeStr.substring(0,5);
  return timeString;
}

const formatDate = (date) => {
  var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  
  if([year, month, day].join('-') === "1970-01-01"){
      return null;
  }else{
      return [year, month, day].join('-');
  } 
}

const currentDate = new Date();
var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Values in milliseconds
const currentDateInMs = currentDate.valueOf();
const SevenDaysInMs = 1000 * 60 * 60 * 24 * 7;
const calculatedDate = new Date(currentDateInMs + SevenDaysInMs);

const IncomingDelivery = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [inputDeliveryDateFrom, setInputDeliveryDateFrom] = useState(new Date());
  const [inputDeliveryDateTo, setInputDeliveryDateTo] = useState(calculatedDate);
  const [inputSupplierName, setInputSupplierName] = useState("");
  const [inputPeriodDescription, setInputPeriodDescription] = useState("");

  const [deliveryDateEditValued, setDeliveryDateEditValued] = useState('');

  const [csvDisabled, setCsvDisabled] = useState(true);

  const dateFormat = 'YYYY-MM-DD';
  //Combobox
  const [periodDescCombobox, setPeriodDescCombobox] = useState([]);
  
  useEffect(() => {
    getEditDataPermission();
    getPeriodDescCombobox();
  },[]);

  
  const [inputData, setInputData] = useState({supplier_name:"",
                                                delivery_date:"",
                                                period_description:"",
                                                remark:"",
                                                updated_by: user,
                                                updated_timestamp:"",
                                                timezone: ""
                                              });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showAddDataModal = () => {
    setIsModalOpen(true);
  };

   const handleOk = () => {
    const config = {
      
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };

    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    
    var strTimeFrom = inputData.period_description.split('-')[0];
    var strTimeTo = inputData.period_description.split('-')[1];

    var timeFrom = strTimeFrom.trim(' ') + timezone;
    var timeTo= strTimeTo.trim(' ') + timezone;

    var dateStr = new Date().toLocaleDateString();

    var timeFromDate = dateStr + ' ' + timeFrom
    var timeToDate = dateStr + ' ' + timeTo

    var convertTimeFrom = new Date(timeFromDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok", hour: 'numeric', minute: 'numeric' })
    var convertTimeTo = new Date(timeToDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok", hour: 'numeric', minute: 'numeric' })

    inputData.period_description = convertTimeFrom + ' - ' + convertTimeTo;
    var updatedTimestamp = new Date()
    inputData.updated_timestamp = updatedTimestamp

    inputData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    axios.post(baseUrl+apiUrl+`/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);
          const oldData = [...modiefiedData]

          var date = new Date().toLocaleDateString();

          var dateTimeFromString = date + ' ' + response.data.time_from;
          var dateTimeToString = date + ' ' + response.data.time_to;

          var TimeFromDateTime = new Date (dateTimeFromString)
          var TimeToDateTime = new Date (dateTimeToString)

          var TimeFrom = new Date(TimeFromDateTime).toLocaleString('en-GB', { timeZone: tzName, hour: 'numeric', minute: 'numeric' })
          var TimeTo = new Date(TimeToDateTime).toLocaleString('en-GB', { timeZone: tzName, hour: 'numeric', minute: 'numeric' })

          response.data.period_description = TimeFrom + ' - ' + TimeTo;

          response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

          const dataAdd = {
            record_id: response.data.record_id,
            supplier_name: response.data.supplier_name,
            delivery_date: response.data.delivery_date,
            period_description: response.data.period_description,
            time_from: response.data.time_from,
            time_to: response.data.time_to,
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);

          setInputData({supplier_name:"",
            delivery_date:"",
            period_description:"",
            remark:"",
            updated_by: user,
            updated_timestamp:"",
          })
        })
        .catch(function (error) {
          if(error.response.status === 401){
            sessionStorage.clear();
            window.location.href = "/";
          }else{
            Swal.fire("Error", error.message, "error");
          }
        });
        
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({supplier_name:"",
        delivery_date:"",
        period_description:"",
        remark:"",
        updated_by: user,
        updated_timestamp:"",
      })
  };

  const loadData = async () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    
    var periodWithTimezone = ""
    
    if (inputPeriodDescription) {
      var timeFromStr = inputPeriodDescription.split(' - ')[0];
      var timeToStr = inputPeriodDescription.split(' - ')[1];

      periodWithTimezone = timeFromStr + timezone + ' - ' +  timeToStr + timezone;
    }
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
              'delivery_date_from': formatDate(inputDeliveryDateFrom),
              'delivery_date_to': formatDate(inputDeliveryDateTo),
              'supplier_name': inputSupplierName,
              'period_description': periodWithTimezone,
              'timezone': tzName
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+apiUrl,config);
      
      var date = new Date().toLocaleDateString();

      for (var i=0; i< response.data.length; i++) {

        var dateTimeFromString = date + ' ' + response.data[i].time_from;
        var dateTimeToString = date + ' ' + response.data[i].time_to;

        var TimeFromDateTime = new Date (dateTimeFromString)
        var TimeToDateTime = new Date (dateTimeToString)

        var TimeFrom = new Date(TimeFromDateTime.getTime() - TimeFromDateTime.getTimezoneOffset()*60*1000).toISOString();
        var TimeTo = new Date(TimeToDateTime.getTime() - TimeToDateTime.getTimezoneOffset()*60*1000).toISOString();
        var strTimeFrom = TimeFrom.split('T')[1] 
        var strTimeTo = TimeTo.split('T')[1]

        var splitTimeFrom = strTimeFrom.split(':00.')[0];
        var splitTimeTo = strTimeTo.split(':00.')[0];
        response.data[i].period_description = splitTimeFrom + ' - ' + splitTimeTo;

        response.data[i].updated_timestamp = new Date(response.data[i].updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

      }
      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);

    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  }
  
  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setInputDeliveryDateFrom(formatDate(new Date()));
    setInputDeliveryDateTo(calculatedDate);
    setInputSupplierName("");
    setInputPeriodDescription("");
    setCsvDisabled(true);
    setInputData({
        supplier_name:"",
        delivery_date:"",
        period_description:"",
        remark:"",
        updated_by: user,
        updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();
        
        const newData = [...modiefiedData];
        const index = newData.findIndex((item) => key === item.key);

        if(deliveryDateEditValued !== ''){
          row.delivery_date = formatDate(deliveryDateEditValued);
        }

        var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
       
        var strTimeFrom = row.period_description.split('-')[0];
        var strTimeTo = row.period_description.split('-')[1];

        var timeFrom = strTimeFrom.trim() + timezone;
        var timeTo= strTimeTo.trim() + timezone;

        var dateStr = new Date().toLocaleDateString();

        var timeFromDate = dateStr + ' ' + timeFrom
        var timeToDate = dateStr + ' ' + timeTo

        var convertTimeFrom = new Date(timeFromDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok", hour: 'numeric', minute: 'numeric' })
        var convertTimeTo = new Date(timeToDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok", hour: 'numeric', minute: 'numeric' })

        row.period_description = convertTimeFrom + ' - ' + convertTimeTo;
        var updatedTimestamp = new Date();
        row.updated_timestamp = updatedTimestamp
        
        const editedData = {
          record_id: newData[index].record_id,
          supplier_name: row.supplier_name,
          delivery_date: row.delivery_date,
          period_description: row.period_description,
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp
        }

        axios.put(baseUrl + apiUrl +`/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            var date = new Date().toLocaleDateString();

            var dateTimeFromString = date + ' ' + response.data.time_from;
            var dateTimeToString = date + ' ' + response.data.time_to;

            var TimeFromDateTime = new Date (dateTimeFromString)
            var TimeToDateTime = new Date (dateTimeToString)

            var TimeFrom = new Date(TimeFromDateTime).toLocaleString('en-GB', { timeZone: tzName, hour: 'numeric', minute: 'numeric' })
            var TimeTo = new Date(TimeToDateTime).toLocaleString('en-GB', { timeZone: tzName, hour: 'numeric', minute: 'numeric' })
  
            response.data.period_description = TimeFrom + ' - ' + TimeTo;

            response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                supplier_name: response.data.supplier_name,
                delivery_date: response.data.delivery_date,
                period_description: response.data.period_description,
                time_from: response.data.time_from,
                time_to: response.data.time_to,
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              console.log(JSON.parse(JSON.stringify(newData)))
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
      }else{
        Swal.fire("Error", error.message, "error");
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        supplier_name: "",
        delivery_date: "",
        period_description: "",
        remark: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = gridData.map(({ body, ...item}) => ({
    ...item,
    key: item.record_id,
    supplier_name: item.supplier_name,
    delivery_date: item.delivery_date,
    period_description: item.period_description,
    remark: item.remark,
    updated_by: item.updated_by,
    updated_timestamp: item.updated_timestamp,
    message: isEmpty(body) ? item.message : body,
  }));
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      width: 50,
      hidden: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      align: "center",
      editTable: true,
      width: 60,
    },
    {
        title: "Period Description",
        dataIndex: "period_description",
        align: "center",
        editTable: true,
        width: 50,
      },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      // editTable: true,
      width: 50,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      // editTable: true,
      width: 80,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: 'right',
          width: 100,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData || editable}>
                    <DeleteOutlined/>
                  </Button>
                
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{marginRight: 1}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();
          })
          .catch(function (error) {
            if(error.response.status === 401){
              sessionStorage.clear();
              window.location.href = "/";
            }else{
              Swal.fire("Error", error.message, "error");
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;

    return (
      <td {...restProps}>
          {editing  ? (
            dataIndex === "delivery_date" ? (
             
             <DatePicker
             onChange={(deliveryDateEditValued) => 
               {
                 deliveryDateEditValued === null ? setDeliveryDateEditValued('') 
                 : setDeliveryDateEditValued(dayjs(formatDate(deliveryDateEditValued), dateFormat))
               }
             } 
             style={{ width: '100%'}}
             value={deliveryDateEditValued !== '' ? dayjs(formatDate(deliveryDateEditValued), dateFormat) 
               : dayjs(record[dataIndex], dateFormat)
             }
           />
             )
             : dataIndex === "period_description" ? (
              <Form.Item 
                name={dataIndex}
                style={{margin: 0 }}
              >
                <Input 
                list="periodDescList"
                onClick={clear}
                />
              </Form.Item>
             ) 
             : dataIndex === "remark" ? (
              <Form.Item 
              name={dataIndex} 
              style={{margin: 0 }}
              rules={
               [
                 {
                 required: false,
                 message: `Please input value ${title} field`,
               },
             ]}
             >
               {input}
              </Form.Item>
               ) 
             :  (
              <Form.Item 
              name={dataIndex} 
              style={{margin: 0 }}
              rules={
               [
                 {
                 required: true,
                 message: `Please input value ${title} field`,
               },
             ]}
             >
               {input}
              </Form.Item>
             )
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    
    var periodWithTimezone = ""
    
    if (inputPeriodDescription) {
      var timeFromStr = inputPeriodDescription.split(' - ')[0];
      var timeToStr = inputPeriodDescription.split(' - ')[1];

      periodWithTimezone = timeFromStr + timezone + ' - ' +  timeToStr + timezone;
    }
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
          'delivery_date_from': formatDate(inputDeliveryDateFrom),
          'delivery_date_to': formatDate(inputDeliveryDateTo),
          'supplier_name': inputSupplierName,
          'period_description': periodWithTimezone,
          'timezone': tzName
        }
   }; 
    axios.get(baseUrl+apiUrl+"/csv", config)
    .then(response => { 
            const csvData = new Blob([response.data], { type: 'text/csv;charset="utf-8";' });
            FileSaver.saveAs(csvData, 'MASTER_INCOMING_DELIVERY.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
        })
  }

  const getPeriodDescCombobox = async () => {
    var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
          'timezone': tzName
      }
      }; 
    const res = await axios.get(baseUrl+"/api/calendar-period/periodCombobox",config);
    const dt = res.data;
    setPeriodDescCombobox(dt);
  }

  //edit_data Permission
  const [editData, setEditData] = useState(false);
  const getEditDataPermission = async () => {
      const config = {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
        }; 
      try{
        const response = await fetch(
          baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json()); 
        // update the state
        const editDataPermission = response.permission;
        for(let i = 0 ; i < editDataPermission.length ;  i++){
          if(editDataPermission[i].module_name === "Incoming Delivery"){
              setEditData(editDataPermission[i].edit_data);
          }
        }
      }catch(error){
          sessionStorage.clear();
          window.location.href = "/";
      }
    }

    const clear = (event) => {
      event.target.value = "";
    };

  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Incoming Delivery</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Delivery Date From: </span><br></br>
            <DatePicker 
              onChange={inputDeliveryDateFrom => setInputDeliveryDateFrom(inputDeliveryDateFrom)} 
              style={{ width: '99%',marginLeft: '3px' }}
              value={inputDeliveryDateFrom ? dayjs(formatDate(inputDeliveryDateFrom), dateFormat) : ''}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span >Delivery Date To: </span>
            <DatePicker 
              onChange={inputDeliveryDateTo => setInputDeliveryDateTo(inputDeliveryDateTo)} 
              style={{ width: '99%' }}
              value={inputDeliveryDateTo ? dayjs(formatDate(inputDeliveryDateTo), dateFormat) : ''}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Supplier Name: </span>
            <Input style={{width: '99%' ,margin:'3px'}} placeholder="" 
            value={inputSupplierName}
            onChange={(e) => setInputSupplierName(e.target.value)}
            />
          </div>
        </Col><Col className="gutter-row" span={12}>
          <div>
            <span style={{}} >Period Description: </span>
            <Input style={{width: '99%' ,marginTop: '3px'}} placeholder="" 
            value={inputPeriodDescription !== "" ? inputPeriodDescription : ""}
            onChange={(e) => setInputPeriodDescription(e.target.value)}
            onClick={clear}
            list="periodDescList"/>
            <datalist id="periodDescList">
              {periodDescCombobox.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </datalist>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Incoming Delivery Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Supplier Name : </span>
                <Input value={inputData.supplier_name} 
                    onChange={e => setInputData({...inputData, supplier_name: e.target.value})}/>
                <span>Delivery Date : </span>
                <DatePicker 
                  style={{ width: '100%' }}
                    onChange={e => setInputData({...inputData, delivery_date: formatDate(e)})
                  }
                />
                <span>Period Description : </span>
                <Input value={inputData.period_description}
                    onChange={e => setInputData({...inputData, period_description: e.target.value})}
                    onClick={clear}
                    list="periodDescList"
                />
                <datalist id="periodDescList">
                  {periodDescCombobox.map((o) => (
                    <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Remark : </span>
                <Input value={inputData.remark}
                    onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '250px' , width: '100%'}}
            scroll={{
              x: 500,
              y: 280
            }}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default IncomingDelivery