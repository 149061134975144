import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal } from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../../environment/environment';
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';

const baseUrl = environment.apiUrl;
const apiUrl = "/api/delivery-lead-time";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const MasterDeliveryLeadTime = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [inputProvinceNameThai, setInputProvinceNameThai] = useState("");
  const [inputProvinceNameEng, setInputProvinceNameEng] = useState("");
  const [inputStateCode, setInputStateCode] = useState("");
  const [inputRegion, setInputRegion] = useState("");

  const [csvDisabled, setCsvDisabled] = useState(true);
  
  useEffect(() => {
    getEditDataPermission();
  },[]);

  var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const [inputData, setInputData] = useState({province_name_th:"",
                                                province_name_en:"",
                                                state_code:"",
                                                region:"",
                                                region_description:"",
                                                normal_lead_time:"",
                                                urgent_lead_time:"",
                                                remark:"",
                                                updated_by: user,
                                                // updated_timestamp:"",
                                              });
  const [isModalOpen, setIsModalOpen] = useState(false);

    const showAddDataModal = () => {
      setIsModalOpen(true);
    };

   const handleOk = () => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };
    var updatedTimestamp = new Date()
    inputData.updated_timestamp = updatedTimestamp
   
    axios.post(baseUrl+apiUrl+`/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);
          const oldData = [...modiefiedData]

          response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

          const dataAdd = {
            record_id: response.data.record_id,
            province_name_th: response.data.province_name_th,
            province_name_en: response.data.province_name_en,
            state_code: response.data.state_code,
            region: response.data.region, 
            region_description: response.data.region_description, 
            normal_lead_time: response.data.normal_lead_time, 
            urgent_lead_time: response.data.urgent_lead_time, 
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp,
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);
          setInputData({province_name_th:"",
            province_name_en:"",
            state_code:"",
            region:"",
            region_description:"",
            normal_lead_time:"",
            urgent_lead_time:"",
            remark:"",
            updated_by: user,
            updated_timestamp:"",
          })
        })
        .catch(function (error) {
          if(error.response.status === 401){
            sessionStorage.clear();
            window.location.href = "/";
            setLoading(false);
          }else{
            Swal.fire("Error", error.message, "error");
            setLoading(false);
          }
        });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({province_name_th:"",
        province_name_en:"",
        state_code:"",
        region:"",
        region_description:"",
        normal_lead_time:"",
        urgent_lead_time:"",
        remark:"",
        updated_by: user,
        // updated_timestamp:"",
      })
  };

  const loadData = async () => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
                'province_name_th': inputProvinceNameThai,
                'province_name_en': inputProvinceNameEng,
                'state_code': inputStateCode,
                'region': inputRegion
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+apiUrl,config);

      for (var i=0; i< response.data.length; i++) {

        response.data[i].updated_timestamp = new Date(response.data[i].updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

      }

      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);

    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  }

  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setInputProvinceNameThai("");
    setInputProvinceNameEng("");
    setInputStateCode("");
    setInputRegion("");
    setCsvDisabled(true);
    setInputData({
        province_name_th:"",
        province_name_en:"",
        state_code:"",
        region:"",
        region_description:"",
        normal_lead_time:"",
        urgent_lead_time:"",
        remark:"",
        updated_by: user,
        updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();
        
        const newData = [...modiefiedData];
        const index = newData.findIndex((item) => key === item.key);

        var updatedTimestamp = new Date();
        row.updated_timestamp = updatedTimestamp

        const editedData = {
          record_id: newData[index].record_id,
          province_name_th: row.province_name_th,
          province_name_en: row.province_name_en,
          state_code: row.state_code,
          region: row.region,
          region_description: row.region_description,
          normal_lead_time: row.normal_lead_time,
          urgent_lead_time: row.urgent_lead_time,
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp
        }

        axios.put(baseUrl + apiUrl +`/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                province_name_th: response.data.province_name_th,
                province_name_en: response.data.province_name_en,
                state_code: response.data.state_code,
                region: response.data.region,
                region_description: response.data.region_description,
                normal_lead_time: response.data.normal_lead_time,
                urgent_lead_time: response.data.urgent_lead_time,
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        province_name_th: "",
        province_name_en: "",
        state_code: "",
        region: "",
        region_description: "",
        remark: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = gridData.map(({ body, ...item}) => ({
    ...item,
    key: item.record_id,
    province_name_th: item.province_name_th,
    province_name_en: item.province_name_en,
    state_code: item.state_code,
    region: item.region,
    region_description: item.region_description,
    normal_lead_time: item.normal_lead_time,
    urgent_lead_time: item.urgent_lead_time,
    remark: item.remark,
    updated_by: item.updated_by,
    updated_timestamp: item.updated_timestamp,
    message: isEmpty(body) ? item.message : body,
  }));
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      hidden: true,
    },
    {
      title: "Province Name Thai",
      dataIndex: "province_name_th",
      align: "center",
      editTable: true,
      width: 105,
      // fixed: true,
    },
    {
      title: "Province Name Eng",
      dataIndex: "province_name_en",
      align: "center",
      editTable: true,
      width: 105,
      // fixed: true,
    },
    {
        title: "State Code",
        dataIndex: "state_code",
        align: "center",
        editTable: true,
        width: 70,
    },
    {
        title: "Region",
        dataIndex: "region",
        align: "center",
        editTable: true,
        width: 50,
    },
    {
        title: "Region Description",
        dataIndex: "region_description",
        align: "center",
        editTable: true,
        width: 100,
    },
    {
        title: "Normal",
        dataIndex: "normal_lead_time",
        align: "center",
        editTable: true,
        width: 55,
    },
    {
        title: "Urgent",
        dataIndex: "urgent_lead_time",
        align: "center",
        editTable: true,
        width: 50,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 55,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      // editTable: true,
      width: 70,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      // editTable: true,
      width: 105,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: true,
          width: 80,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData || editable}>
                    <DeleteOutlined/>
                  </Button>
                
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{marginRight: 1}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();
          })
          .catch(function (error) {
            if(error.response.status === 401){
              sessionStorage.clear();
              window.location.href = "/";
            }else{
              Swal.fire("Error", error.message, "error");
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;

    return (
      <td {...restProps}>
          {editing  ? (
             <Form.Item 
             name={dataIndex} 
             style={{margin: 0 }}
             rules={
              [
                {
                required: false,
                message: `Please input value ${title} field`,
              },
            ]}
            >
              {input}
             </Form.Item>
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {

    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
          'province_name_th': inputProvinceNameThai,
          'province_name_en': inputProvinceNameEng,
          'state_code': inputStateCode,
          'region': inputRegion,
          'timezone': tzName
        }
   }; 
    axios.get(baseUrl+apiUrl+"/csv", config)
    .then(response => { 
            var csvContent = "1,1,23\ná,é,í,ú,ü,ű,ó,ö,ő\n";
            const csvData = new Blob(["\uFEFF"+response.data], { type: 'text/csv;charset="utf-8,%EF%BB%BF";' },encodeURI(csvContent));
            FileSaver.saveAs(csvData, 'MASTER_DELIVERY_LEAD_TIME.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
        })
  }

  //edit_data Permission
  const [editData, setEditData] = useState(false);
  const getEditDataPermission = async () => {
      const config = {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
        }; 
      try{
        const response = await fetch(
          baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json()); 
        // update the state
        const editDataPermission = response.permission;
        for(let i = 0 ; i < editDataPermission.length ;  i++){
          if(editDataPermission[i].module_name === "Delivery Lead Time"){
              setEditData(editDataPermission[i].edit_data);
          }
        }
      }catch(error){
          sessionStorage.removeItem("accessToken");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("roleName");
          sessionStorage.clear();
          window.sessionStorage.clear();
          window.location.href = "/";
      }
    }

  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Delivery Lead Time</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{margin: '4px'}}>Province Name Thai : </span><br></br>
            <Input style={{marginLeft : '4px'}} placeholder="" 
            value={inputProvinceNameThai}
            onChange={(e) => setInputProvinceNameThai(e.target.value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{}}>Province Name Eng : </span>
            <Input style={{}} placeholder="" 
            value={inputProvinceNameEng}
            onChange={(e) => setInputProvinceNameEng(e.target.value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{margin: '4px'}}>State Code : </span>
            <Input style={{marginLeft : '4px'}} placeholder="" 
            value={inputStateCode}
            onChange={(e) => setInputStateCode(e.target.value)}
            />
          </div>
        </Col><Col className="gutter-row" span={12}>
          <div>
            <span style={{}}>Region : </span>
            <Input style={{}} placeholder="" 
            value={inputRegion}
            onChange={(e) => setInputRegion(e.target.value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div style={{marginTop : '5px'}}>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Delivery Lead Time Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Province Name Th : </span>
                <Input value={inputData.province_name_th} 
                    onChange={e => setInputData({...inputData, province_name_th: e.target.value})}/>
                <span>Province Name Eng : </span>
                <Input value={inputData.province_name_en} 
                    onChange={e => setInputData({...inputData, province_name_en: e.target.value})}/>
                <span>State Code : </span>
                <Input value={inputData.state_code} 
                    onChange={e => setInputData({...inputData, state_code: e.target.value})}/>
                <span>Region : </span>
                <Input value={inputData.region} 
                    onChange={e => setInputData({...inputData, region: e.target.value})}/>
                <span>Region Description : </span>
                <Input value={inputData.region_description}
                    onChange={e => setInputData({...inputData, region_description: e.target.value})}
                />
                <span>Normal Lead Time : </span>
                <Input value={inputData.normal_lead_time} 
                    onChange={e => setInputData({...inputData, normal_lead_time: e.target.value})}/>
                <span>Urgent Lead Time : </span>
                <Input value={inputData.urgent_lead_time} 
                    onChange={e => setInputData({...inputData, urgent_lead_time: e.target.value})}/>
                <span>Remark : </span>
                <Input value={inputData.remark}
                    onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '230px', width: '100%' }}
            scroll={{
              x: 800,
              y: 270
            }}
            sticky= {true}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default MasterDeliveryLeadTime