import React, { useState} from 'react'
import { environment } from '../../environment/environment';
import Swal from 'sweetalert2';
import { Form, Input,
     Button, 
    //  Checkbox, 
     Card, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
const { Title } = Typography;

const baseUrl =  environment.apiUrl;
const apiUrl = '/api/auth/sign-in';

const SignIn = () => {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState("");

    async function loginUser(credentials) {
        return fetch(baseUrl+apiUrl,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
       }
       const handleSubmit = async (e) => {
            
            // e.preventDefault();
            if (username && password) {
                // Submit the data to the backend for authentication
                const response = await loginUser({
                    username,
                    password
                });
                if ('accessToken' in response) {
                    sessionStorage.setItem('accessToken', response['accessToken']);
                    sessionStorage.setItem('username', JSON.stringify(response['username']));
                    sessionStorage.setItem('roleName', JSON.stringify(response['roleName']));
                    window.location.href = "/master-data/master-sales-target";
                } else {
                    Swal.fire("Failed", response.message, "error");
                }
            } else {
                Swal.fire("Failed", "Both fields are required!!!", "error");
            }
      }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "lightgray",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: 500 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={2}>LOGIN </Title>
        </div>
        <Form
          name="normal_login"
          className="login-form"
        //   initialValues={{ remember: true }}
          onFinish={handleSubmit}
        // onSubmit={handleSubmit}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            // rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              SIGN IN
            </Button>
            {/* Don't have an account{" "}
            <a href="" onClick={handleRegister}>
              sign up
            </a> */}
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SignIn;