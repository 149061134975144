import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal , DatePicker} from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../../environment/environment';
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';
import dayjs from 'dayjs';


const baseUrl = environment.apiUrl;
const apiUrl = "/api/calendar";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const formatDate = (date) => {
  var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  
  if([year, month, day].join('-') === "1970-01-01"){
      return null;
  }else if([year, month, day].join('-') === "NaN-NaN-NaN"){
      return null;
  }
  else{
      return [year, month, day].join('-');
  } 
}

// const fmDate = (date) => (date.toISOString().split('T')[0]);
var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

const currentDate = new Date();

// Values in milliseconds
const currentDateInMs = currentDate.valueOf();
const ThirtyDaysInMs = 1000 * 60 * 60 * 24 * 30;
const calculatedDate = new Date(currentDateInMs + ThirtyDaysInMs);

const MasterCalendar = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [inputDateOfYearFrom, setInputDateOfYearFrom] = useState(new Date());
  const [inputDateOfYearTo, setInputDateOfYearTo] = useState(calculatedDate);
  const [inputDateType, setInputDateType] = useState("");
  const [inputOperate, setInputOperate] = useState("");

  const [dateOfYearEditValued , setDateOfYearEditValued] = useState("");
  const [csvDisabled, setCsvDisabled] = useState(true);

  const dateFormat = 'YYYY-MM-DD';
  //Combobox
  const [dateTypeCombobox, setDateTypeCombobox] = useState([]);
  const [operateCombobox, setOperateCombobox] = useState([]);

  useEffect(() => {
    getDateTypeCombobox();
    getEditDataPermission();
    getOperateCombobox();
  },[]);

  
  const [inputData, setInputData] = useState({date_of_year:"",
                                                date_type:"",
                                                date_desc:"",
                                                operate:"",
                                                remark:"",
                                                updated_by: user,
                                                // updated_timestamp:"",
                                              });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showAddDataModal = () => {
    setIsModalOpen(true);
  };
   const handleOk = () => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };

    var updatedTimestamp = new Date()
    inputData.updated_timestamp = updatedTimestamp
   
    axios.post(baseUrl+apiUrl+`/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);
          const oldData = [...modiefiedData]

          response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

          const dataAdd = {
            record_id: response.data.record_id,
            date_of_year: response.data.date_of_year,
            date_type: response.data.date_type,
            date_desc: response.data.date_desc,
            operate: response.data.operate, 
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);
          setInputData({date_of_year:"",
            date_type:"",
            date_desc:"",
            operate:"",
            remark:"",
            updated_by: user,
            updated_timestamp:"",
          })

        })
        .catch(function (error) {
          if(error.response.status === 401){
            Swal.fire("Token Expired", error.message, "error");
            sessionStorage.clear();
            window.location.href = "/";
            setLoading(false);
          }else{
            Swal.fire("Error", error.message, "error");
          }
        });
        
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({date_of_year:"",
            date_type:"",
            date_desc:"",
            operate:"",
            remark:"",
            updated_by: user,
            updated_timestamp:"",
          })
  };
  const loadData = async () => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
              'date_of_year_from': formatDate(inputDateOfYearFrom),
              'date_of_year_to': formatDate(inputDateOfYearTo),
              'date_type': inputDateType,
              'operate': inputOperate,
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+apiUrl,config);

      for (var i=0; i< response.data.length; i++) {

        response.data[i].updated_timestamp = new Date(response.data[i].updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

      }
      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);

    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  }
  
  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setInputDateOfYearFrom(new Date());
    setInputDateOfYearTo(calculatedDate);
    setInputDateType("");
    setInputOperate("");
    setCsvDisabled(true);
    setInputData({
        date_of_year:"",
        date_type:"",
        date_desc:"",
        operate:"",
        remark:"",
        updated_by: user,
        updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();
        
        const newData = [...modiefiedData];
        const index = newData.findIndex((item) => key === item.key);

        if(dateOfYearEditValued !== ''){
          row.date_of_year = formatDate(dateOfYearEditValued);
        }
        var updatedTimestamp = new Date();
        row.updated_timestamp = updatedTimestamp

        const editedData = {
          record_id: newData[index].record_id,
          date_of_year: row.date_of_year,
          date_type: row.date_type,
          date_desc: row.date_desc,
          operate: row.operate,
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp
        }

        axios.put(baseUrl + apiUrl +`/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                date_of_year: response.data.date_of_year,
                date_type: response.data.date_type,
                date_desc: response.data.date_desc,
                operate: response.data.operate, 
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        date_of_year:"",
        date_type:"",
        date_desc:"",
        operate:"",
        remark: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = gridData.map(({ body, ...item}) => ({
    ...item,
    key: item.record_id,
    date_of_year: item.date_of_year,
    date_type: item.date_type,
    date_desc: item.date_desc,
    operate: item.operate,
    remark: item.remark,
    updated_by: item.updated_by,
    updated_timestamp: item.updated_timestamp,
    message: isEmpty(body) ? item.message : body,
  }));
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      width: 50,
      hidden: true,

    },
    {
      title: "Date",
      dataIndex: "date_of_year",
      align: "center",
      editTable: true,
      width: 50,
      // fixed: true,
    },
    {
      title: "Date Type",
      dataIndex: "date_type",
      align: "center",
      editTable: true,
      width: 40,
    },
    {
        title: "Date Description",
        dataIndex: "date_desc",
        align: "center",
        editTable: true,
        width: 50,
    },
    {
      title: "Operate",
      dataIndex: "operate",
      align: "center",
      editTable: true,
      width: 30,
  },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 50,
      // fixed: true,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      width: 50,
      // fixed: true,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      width: 50,
      // fixed: true,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: 'right',
          width: 50,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData||editable}>
                    <DeleteOutlined/>
                  </Button>
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{marginRight: 1}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();
          })
          .catch(function (error) {
            if(error.response.status === 401){
              sessionStorage.clear();
              window.location.href = "/";
            }else{
              Swal.fire("Error", error.message, "error");
              setLoading(false);
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;

    return (
      <td {...restProps}>
          {editing  ? (
            dataIndex === "date_of_year" ? (
              <DatePicker
                onChange={(dateOfYearEditValued) => 
                  {
                    dateOfYearEditValued === null ? setDateOfYearEditValued(dayjs(record[dataIndex], dateFormat)) 
                    : setDateOfYearEditValued(dayjs(formatDate(dateOfYearEditValued), dateFormat))
                  }
                } 
                style={{ width: '100%'}}
                value={dateOfYearEditValued !== "" ? dayjs(formatDate(dateOfYearEditValued), dateFormat) 
                  : dayjs(record[dataIndex], dateFormat)
                }
            />
           
             ) : dataIndex === "operate" ? (
            <Form.Item 
              name={dataIndex}
              style={{margin: 0 }}
            >
              <Input 
              list="operateList"
              onClick={clear}
              />
            </Form.Item>
             ) 
             : dataIndex === "date_type" ? (
              <Form.Item 
                name={dataIndex}
                style={{margin: 0 }}
              >
                <Input 
                list="dateTypeList"
                onClick={clear}
                />
              </Form.Item>
               ) 
               : (
              <Form.Item 
              name={dataIndex} 
              style={{margin: 0 }}
              rules={
               [
                 {
                 required: false,
                 message: `Please input value ${title} field`,
               },
             ]}
             >
               {input}
              </Form.Item>
             )
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {

    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
          'date_of_year_from': formatDate(inputDateOfYearFrom),
          'date_of_year_to': formatDate(inputDateOfYearTo),
          'date_type': inputDateType,
          'operate': inputOperate,
          'timezone': tzName
        }
   }; 
    axios.get(baseUrl+apiUrl+"/csv", config)
    .then(response => { 
            const csvData = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            FileSaver.saveAs(csvData, 'MASTER_CALENDAR.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
        })
  }

  const getDateTypeCombobox = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      }; 
      const res = await axios.get(baseUrl+"/api/date-type/dateTypeCombobox",config);
    const dt = res.data;
    setDateTypeCombobox(dt);
  }

  const getOperateCombobox = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      }; 
      const res = await axios.get(baseUrl+"/api/calendar/operateCombobox",config);
    const dt = res.data;
    setOperateCombobox(dt);
  }

  //edit_data Permission
  const [editData, setEditData] = useState(false);
  const getEditDataPermission = async () => {
      const config = {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
        }; 
      try{
        const response = await fetch(
          baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json()); 
        // update the state
        const editDataPermission = response.permission;
        for(let i = 0 ; i < editDataPermission.length ;  i++){
          if(editDataPermission[i].module_name === "Calendar"){
              setEditData(editDataPermission[i].edit_data);
          }
        }
      }catch(error){
          console.log(error)
          sessionStorage.clear();
          window.location.href = "/";
      }
    }

    const clear = (event) => {
      event.target.value = "";
    };


  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Calendar</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Date From: </span><br></br>
            <DatePicker 
              name="date_from"
              onChange={(inputDateOfYearFrom) => 
                {
                  inputDateOfYearFrom === null ? setInputDateOfYearFrom('') 
                  : setInputDateOfYearFrom(dayjs(formatDate(inputDateOfYearFrom), dateFormat))
                }
              } 
              style={{width:'100%', marginLeft: '5px'}}
              value={inputDateOfYearFrom ? dayjs(formatDate(inputDateOfYearFrom), dateFormat) : ''}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span >Date To: </span>
            <DatePicker 
              name="date_to"
              onChange={(inputDateOfYearTo) => 
                {
                  inputDateOfYearTo === null ? setInputDateOfYearTo('') 
                  : setInputDateOfYearTo(dayjs(formatDate(inputDateOfYearTo), dateFormat))
                }
              } 
              style={{ width: '100%'}}
              value={inputDateOfYearTo ? dayjs(formatDate(inputDateOfYearTo), dateFormat) : ''}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Date Type: </span>
            <Input style={{ marginLeft: '5px'}} placeholder="" 
            value={inputDateType}
            onChange={(e) => {
              setInputDateType(e.target.value)
            }}
            onClick={clear}
            list="dateTypeList"/>
            <datalist id="dateTypeList">
              <option label=" "></option>
              {dateTypeCombobox.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </datalist>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '1px'}}>Operate: </span>
            <Input placeholder="" 
            value={inputOperate}
            onChange={(e) => {
              setInputOperate(e.target.value)
            }}
            onClick={clear}
            list="operateList"/>
            <datalist id="operateList">
              <option label=" "></option>
              {operateCombobox.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </datalist>
          </div>
        </Col>
        <Col className="gutter-row" style={{marginTop:'5px'}} span={12}>
          <div>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Calendar Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Date : </span>
                <DatePicker 
                style={{ width: '100%' }}
                onChange={e => setInputData({...inputData, date_of_year: formatDate(e)})}
                
                />
                <span>Date Type : </span>
                <Input value={inputData.date_type}
                    onChange={e => setInputData({...inputData, date_type: e.target.value})}
                    onClick={clear}
                    list="dateTypeList"
                />
                <datalist id="dateTypeList">
                  {dateTypeCombobox.map((o) => (
                    <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Date Description : </span>
                <Input value={inputData.date_desc}
                    onChange={e => setInputData({...inputData, date_desc: e.target.value})}/>
                <span>Operate : </span>
                <Input value={inputData.operate}
                    onChange={e => setInputData({...inputData, operate: e.target.value})}
                    onClick={clear}
                    list="operateList"
                />
                <datalist id="operateList">
                  {operateCombobox.map((o) => (
                    <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Remark : </span>
                <Input value={inputData.remark}
                    onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            // columns={columns}
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '300px', width: '99.9%' }}
            scroll={{
              x: 100,
              y: 280
            }}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default MasterCalendar