import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal , TimePicker} from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../../environment/environment';
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import moment from 'moment/moment';

const baseUrl = environment.apiUrl;
const apiUrl = "/api/calendar-period";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const formatTime = (timeStr) => {
  const timeString = timeStr.substring(0,5);
  return timeString;
}
var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone

const MasterCalendarPeriod = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [inputTimeFromStart, setInputTimeFromStart] = useState('');
  const [inputTimeFromEnd, setInputTimeFromEnd] = useState('');
  const [inputTimeToStart, setInputTimeToStart] = useState('');
  const [inputTimeToEnd, setInputTimeToEnd] = useState('');

  const [timeFromEditValued, setTimeFromEditValued] = useState('');
  const [timeToEditValued, setTimeToEditValued] = useState('');

  const [csvDisabled, setCsvDisabled] = useState(true);

  //Combobox
  
  useEffect(() => {
    getEditDataPermission();
  },[]);

  
  const [inputData, setInputData] = useState({time_from:"",
                                                time_to:"",
                                                period_description:"",
                                                remark:"",
                                                updated_by: user,
                                                // updated_timestamp:"",
                                              });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showAddDataModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    
    var timeFrom = inputData.time_from + timezone;
    var timeTo = inputData.time_to + timezone;

    var dateStr = new Date().toLocaleDateString();
    var timeFromDate = dateStr + ' ' + timeFrom
    var timeToDate = dateStr + ' ' + timeTo
    var updatedTimestamp = new Date()

    var convertTimeFrom = new Date(timeFromDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
    var convertTimeTo = new Date(timeToDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
     
    inputData.time_from = convertTimeFrom.split(', ')[1] + '+07'
    inputData.time_to = convertTimeTo.split(', ')[1] + '+07'
    inputData.updated_timestamp = updatedTimestamp

    axios.post(baseUrl+apiUrl+`/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);

          const oldData = [...modiefiedData]
        
          var date = new Date().toLocaleDateString();

          var dateTimeFromString = date + ' ' + response.data.time_from;
          var dateTimeToString = date + ' ' + response.data.time_to;

          var TimeFromDateTime = new Date (dateTimeFromString)
          var TimeToDateTime = new Date (dateTimeToString)

          var TimeFrom = new Date(TimeFromDateTime.getTime() - TimeFromDateTime.getTimezoneOffset()*60*1000).toISOString();
          var TimeTo = new Date(TimeToDateTime.getTime() - TimeToDateTime.getTimezoneOffset()*60*1000).toISOString();
          var timeFromStr = TimeFrom.split('T')[1]
          response.data.time_from = timeFromStr.split(':00.')[0]
          var timeToStr = TimeTo.split('T')[1]
          response.data.time_to = timeToStr.split(':00.')[0]

          var updatedTimestamp = new Date (response.data.updated_timestamp)
          var updatedTimestampWithTimezone = new Date(updatedTimestamp.getTime() - updatedTimestamp.getTimezoneOffset()*60*1000).toISOString();
        
          var dateTimestamp = updatedTimestampWithTimezone.split('T')[0];
          var timeTimestamp = updatedTimestampWithTimezone.split('T')[1];
          var timeSplit = timeTimestamp.split('.')[0];

          response.data.updated_timestamp = dateTimestamp + ' ' + timeSplit;

          const dataAdd = {
            record_id: response.data.record_id,
            time_from: response.data.time_from,
            time_to: response.data.time_to,
            period_description: response.data.period_description,
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);
          setInputData({time_from:"",
            time_to:"",
            period_description:"",
            remark:"",
            updated_by: user,
            updated_timestamp:"",
          })
        })
        .catch(function (error) {
          if(error.response.status === 401){
            Swal.fire("Token Expired", error.message, "error");
            setLoading(false);
          }else{
            Swal.fire("Error", error.message, "error");
            setLoading(false);
          }
        });
        
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({
      time_from:"",
      time_to:"",
      period_description:"",
      remark:"",
      updated_by: user,
      updated_timestamp:"",
    })
  };

  const loadData = async () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    var dateStr = new Date().toLocaleDateString();

    var timeFromStartWithTimezone = ""
    var timeFromEndWithTimezone = ""
    var timeToStartWithTimezone = ""
    var timeToEndWithTimezone = ""

    if (inputTimeFromStart) {
      var timeFromStart = inputTimeFromStart + timezone;
      var timeFromStartDate = dateStr + ' ' + timeFromStart
      var convertTimeFromStart = new Date(timeFromStartDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeFromStartWithTimezone = convertTimeFromStart.split(', ')[1] + '+07'
    }
    if (inputTimeFromEnd) {
      var timeFromEnd = inputTimeFromEnd + timezone;
      var timeFromEndDate = dateStr + ' ' + timeFromEnd
      var convertTimeFromEnd = new Date(timeFromEndDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeFromEndWithTimezone = convertTimeFromEnd.split(', ')[1] + '+07'
    }
    if (inputTimeToStart) {
      var timeToStart = inputTimeToStart + timezone;
      var timeToStartDate = dateStr + ' ' + timeToStart
      var convertTimeToStart = new Date(timeToStartDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeToStartWithTimezone = convertTimeToStart.split(', ')[1] + '+07'
    }
    if (inputTimeToEnd) {
      var timeToEnd = inputTimeToEnd + timezone;
      var timeToEndDate = dateStr + ' ' +  timeToEnd
      var convertTimeTOEnd = new Date(timeToEndDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeToEndWithTimezone = convertTimeTOEnd.split(', ')[1] + '+07'
    } 
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
              'time_from_start': timeFromStartWithTimezone,
              'time_from_end': timeFromEndWithTimezone,
              'time_to_start': timeToStartWithTimezone,
              'time_to_end': timeToEndWithTimezone,
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+apiUrl,config);
      
      var date = new Date().toLocaleDateString();

      for (var i=0; i< response.data.length; i++) {
        var dateTimeFromString = date + ' ' + response.data[i].time_from;
        var dateTimeToString = date + ' ' + response.data[i].time_to;

        var TimeFromDateTime = new Date (dateTimeFromString)
        var TimeToDateTime = new Date (dateTimeToString)

        var TimeFrom = new Date(TimeFromDateTime.getTime() - TimeFromDateTime.getTimezoneOffset()*60*1000).toISOString();
        var TimeTo = new Date(TimeToDateTime.getTime() - TimeToDateTime.getTimezoneOffset()*60*1000).toISOString();

        var timeTimeFrom =  TimeFrom.split('T')[1]
        response.data[i].time_from = timeTimeFrom.split(':00.')[0]
        var timeTimeTo =  TimeTo.split('T')[1]
        response.data[i].time_to = timeTimeTo.split(':00.')[0]

        var updatedTimestamp = new Date (response.data[i].updated_timestamp)
        var updatedTimestampWithTimezone = new Date(updatedTimestamp.getTime() - updatedTimestamp.getTimezoneOffset()*60*1000).toISOString();
       
        var dateTimestamp = updatedTimestampWithTimezone.split('T')[0];
        var timeTimestamp = updatedTimestampWithTimezone.split('T')[1];
        var timeSplit = timeTimestamp.split('.')[0];

        response.data[i].updated_timestamp = dateTimestamp + ' ' + timeSplit;

      }

      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);

    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  };
  
  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setInputTimeFromStart("");
    setInputTimeFromEnd("");
    setInputTimeToStart("");
    setInputTimeToEnd("");
    setCsvDisabled(true);
    setInputData({
        time_from:"",
        time_to:"",
        period_description:"",
        remark:"",
        updated_by: user,
        // updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();
        
        const newData = [...modiefiedData];
        const index = newData.findIndex((item) => key === item.key);

        if(timeFromEditValued !== ''){
          row.time_from = timeFromEditValued;
        }else{
          row.time_from = formatTime(newData[index].time_from);
        }
        if(timeToEditValued !== ''){
          row.time_to = timeToEditValued;
        }else{
          row.time_to = formatTime(newData[index].time_to);
        }

        var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
       
        var timeFrom = row.time_from + timezone;
        var timeTo = row.time_to + timezone;

        var dateStr = new Date().toLocaleDateString();
        var timeFromDate = dateStr + ' ' + timeFrom
        var timeToDate = dateStr + ' ' + timeTo
        var updatedTimestamp = new Date()

        var convertTimeFrom = new Date(timeFromDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
        var convertTimeTo = new Date(timeToDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
        
        row.time_from = convertTimeFrom.split(', ')[1] + '+07'
        row.time_to = convertTimeTo.split(', ')[1] + '+07'
        row.updated_timestamp = updatedTimestamp

        const editedData = {
          record_id: newData[index].record_id,
          time_from: row.time_from,
          time_to: row.time_to,
          period_description: row.period_description,
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp
        }

        axios.put(baseUrl + apiUrl +`/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            var date = new Date().toLocaleDateString();

            var dateTimeFromString = date + ' ' + response.data.time_from;
            var dateTimeToString = date + ' ' + response.data.time_to;

            var TimeFromDateTime = new Date (dateTimeFromString)
            var TimeToDateTime = new Date (dateTimeToString)

            var TimeFrom = new Date(TimeFromDateTime.getTime() - TimeFromDateTime.getTimezoneOffset()*60*1000).toISOString();
            var TimeTo = new Date(TimeToDateTime.getTime() - TimeToDateTime.getTimezoneOffset()*60*1000).toISOString();
            var timeFromStr = TimeFrom.split('T')[1]
            response.data.time_from = timeFromStr.split(':00.')[0]
            var timeToStr = TimeTo.split('T')[1]
            response.data.time_to = timeToStr.split(':00.')[0]

            var updatedTimestamp = new Date (response.data.updated_timestamp)
            var updatedTimestampWithTimezone = new Date(updatedTimestamp.getTime() - updatedTimestamp.getTimezoneOffset()*60*1000).toISOString();
          
            var dateTimestamp = updatedTimestampWithTimezone.split('T')[0];
            var timeTimestamp = updatedTimestampWithTimezone.split('T')[1];
            var timeSplit = timeTimestamp.split('.')[0];

            response.data.updated_timestamp = dateTimestamp + ' ' + timeSplit;

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                time_from: response.data.time_from,
                time_to: response.data.time_to,
                period_description: response.data.period_description,
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
      }else{
        Swal.fire("Error", error.message, "error");
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        time_from:"",
        time_to:"",
        period_description:"",
        remark: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = gridData.map(({ body, ...item}) => ({
    ...item,
    key: item.record_id,
    // time_from: formatTime(item.time_from),
    time_from: item.time_from,
    // time_to: formatTime(item.time_to),
    time_to: item.time_to,
    date_desc: item.date_desc,
    operate: item.operate,
    remark: item.remark,
    updated_by: item.updated_by,
    updated_timestamp: item.updated_timestamp,
    message: isEmpty(body) ? item.message : body,
  }));
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      // width: 50,
      hidden: true,
    },
    {
      title: "Time From",
      dataIndex: "time_from",
      align: "center",
      editTable: true,
      width: 30,
    },
    {
      title: "Time To",
      dataIndex: "time_to",
      align: "center",
      editTable: true,
      width: 30,
    },
    {
        title: "Period Description",
        dataIndex: "period_description",
        align: "center",
        editTable: true,
        width: 50,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      // editTable: true,
      width: 40,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      // editTable: true,
      width: 50,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: 'right',
          width: 50,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData || editable}>
                    <DeleteOutlined/>
                  </Button>
                
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{marginRight: 1}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();
          })
          .catch(function (error) {
            if(error.response.status === 401){
              sessionStorage.clear();
              window.location.href = "/";
            }else{
              Swal.fire("Error", error.message, "error");
              setLoading(false);
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;

    return (
      <td {...restProps}>
          {editing  ? (
            dataIndex === "time_from"  ? (
              <TimePicker
                  style={{ width: '100%'}}
                  format={'HH:mm'}
                  value={
                    timeFromEditValued ? 
                    dayjs(timeFromEditValued, 'HH:mm')
                    : dayjs(record[dataIndex], 'HH:mm')
                  }
                  onChange={(value, dateString) => {
                    timeFromEditValued === null ?
                    setTimeFromEditValued(dayjs(record[dataIndex],'HH:mm'))
                    :
                    setTimeFromEditValued(dateString)
                  }}
                />
             ) : dataIndex === "time_to" ? (
                <TimePicker
                  style={{ width: '100%'}}
                  format={'HH:mm'}
                  value={
                    timeToEditValued ? 
                    dayjs(timeToEditValued, 'HH:mm')
                    : dayjs(record[dataIndex], 'HH:mm')
                  }
                  onChange={(value, dateString) => {
                    timeToEditValued === null ?
                    setTimeToEditValued(dayjs(record[dataIndex],'HH:mm'))
                    :
                    setTimeToEditValued(dateString);
                  }}
                />
             )  
             :  (
              <Form.Item 
              name={dataIndex} 
              style={{margin: 0 }}
              rules={
               [
                 {
                 required: false,
                 message: `Please input value ${title} field`,
               },
             ]}
             >
               {input}
              </Form.Item>
              )
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) 
    var dateStr = new Date().toLocaleDateString();

    var timeFromStartWithTimezone = ""
    var timeFromEndWithTimezone = ""
    var timeToStartWithTimezone = ""
    var timeToEndWithTimezone = ""

    if (inputTimeFromStart) {
      var timeFromStart = inputTimeFromStart + timezone;
      var timeFromStartDate = dateStr + ' ' + timeFromStart
      var convertTimeFromStart = new Date(timeFromStartDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeFromStartWithTimezone = convertTimeFromStart.split(', ')[1] + '+07'
    }
    if (inputTimeFromEnd) {
      var timeFromEnd = inputTimeFromEnd + timezone;
      var timeFromEndDate = dateStr + ' ' + timeFromEnd
      var convertTimeFromEnd = new Date(timeFromEndDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeFromEndWithTimezone = convertTimeFromEnd.split(', ')[1] + '+07'
    }
    if (inputTimeToStart) {
      var timeToStart = inputTimeToStart + timezone;
      var timeToStartDate = dateStr + ' ' + timeToStart
      var convertTimeToStart = new Date(timeToStartDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeToStartWithTimezone = convertTimeToStart.split(', ')[1] + '+07'
    }
    if (inputTimeToEnd) {
      var timeToEnd = inputTimeToEnd + timezone;
      var timeToEndDate = dateStr + ' ' +  timeToEnd
      var convertTimeTOEnd = new Date(timeToEndDate).toLocaleString('en-GB', { timeZone: "Asia/Bangkok" })
      timeToEndWithTimezone = convertTimeTOEnd.split(', ')[1] + '+07'
    } 
       
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
            'time_from_start': timeFromStartWithTimezone,
            'time_from_end': timeFromEndWithTimezone,
            'time_to_start': timeToStartWithTimezone,
            'time_to_end': timeToEndWithTimezone,
            'timezone': tzName
        }
    };
    axios.get(baseUrl+apiUrl+"/csv", config)
    .then(response => { 
            const csvData = new Blob([response.data], { type: 'text/csv;charset="utf-8";' });
            FileSaver.saveAs(csvData, 'MASTER_CALENDAR_PERIOD.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
        })
  }

  //edit_data
  const [editData, setEditData] = useState(false);
  const getEditDataPermission = async () => {
      const config = {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
        }; 
      try{
        const response = await fetch(
          baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json()); 
        // update the state
        const editDataPermission = response.permission;
        for(let i = 0 ; i < editDataPermission.length ;  i++){
          if(editDataPermission[i].module_name === "Calendar Period"){
              setEditData(editDataPermission[i].edit_data);
          }
        }
      }catch(error){
          sessionStorage.clear();
          window.location.href = "/";
      }
    }


  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Calendar Period</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Time From Start: </span><br></br>
            <TimePicker
              style={{marginLeft: '5px', width: '99%'}}
              format={'HH:mm'}
              value={inputTimeFromStart ? dayjs(inputTimeFromStart, 'HH:mm') : ''}
              onChange={(value, dateString) => {
                setInputTimeFromStart(dateString);
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span>Time From End: </span>
            <TimePicker
              style={{marginLeft: '0px', width: '99%'}}
              format={'HH:mm'}
              value={inputTimeFromEnd? dayjs(inputTimeFromEnd, 'HH:mm') : ''}
              onChange={(value, dateString) => {
                setInputTimeFromEnd(dateString);
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span style={{marginLeft: '5px'}}>Time To Start: </span><br></br>
            <TimePicker
              style={{marginLeft: '5px', width: '99%'}}
              format={'HH:mm'}
              value={inputTimeToStart ? dayjs(inputTimeToStart, 'HH:mm') : ''}
              onChange={(value, dateString) => {
                var offset = new Date().getTimezoneOffset()
                var o = Math.abs(offset);
                var timezone = ":" + ("00" + (o % 60)).slice(-2) + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2)
                setInputTimeToStart(dateString);
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <span>Time To End: </span>
            <TimePicker
              style={{marginLeft: '0px', width: '99%'}}
              format={'HH:mm'}
              value={inputTimeToEnd ? dayjs(inputTimeToEnd, 'HH:mm') : ''}
              onChange={(value, dateString) => {
                setInputTimeToEnd(dateString);
              }}
            />
          </div>
        </Col>
        
        <Col className="gutter-row" style={{marginTop:'5px'}} span={12}>
          <div>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Calendar Period Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Time From : </span>
                <TimePicker
                  style={{ width: '100%'}}
                  format={'HH:mm'}
                  onChange={(value, dateString) => {
                    setInputData({...inputData, time_from: dateString});
                  }}
                />
                <span>Time To : </span>
                <TimePicker
                  style={{ width: '100%'}}
                  format={'HH:mm'}
                  onChange={(value, dateString) => {
                    setInputData({...inputData, time_to: dateString});
                  }}
                />
                <span>Period Description : </span>
                <Input value={inputData.date_desc}
                    onChange={e => setInputData({...inputData, period_description: e.target.value})}/>
                <span>Remark : </span>
                <Input value={inputData.remark}
                    onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '300px' , width: '99.9%'}}
            scroll={{
              x: 100,
              y: 280
            }}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default MasterCalendarPeriod