import '../../../App.css'
import React, { useState, useEffect } from 'react'
import { Input, Table, Button ,
  Col, Row, 
  Space, Form, Modal} from 'antd';
import { EditOutlined , DeleteOutlined } from "@ant-design/icons";
import axios from 'axios';
import { environment } from '../../../environment/environment';
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import FileSaver from 'file-saver';

const baseUrl = environment.apiUrl;
const apiUrl = "/api/picking-lead-time";
const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

const MasterPickingLeadTime = () => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowKey, setEditRowKey] = useState("");
  const [form] = Form.useForm();

  const [roomInput, setRoomInput] = useState("");

  const [csvDisabled, setCsvDisabled] = useState(true);

  //Combobox
  const [roomCombobox,setRoomCombobox] = useState([]);

  useEffect(() => {
    getRoomCombobox();
    getEditDataPermission();
  },[]);

  var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [inputData, setInputData] = useState({room:"",
                                                lead_time:"",
                                                remark:"",
                                                updated_by: user,
                                                // updated_timestamp:"",
                                              });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showAddDataModal = () => {
    setIsModalOpen(true);
  };

   const handleOk = () => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
    };

    var updatedTimestamp = new Date()
    inputData.updated_timestamp = updatedTimestamp

    axios.post(baseUrl + apiUrl + `/create`,inputData,config)
        .then(function (response) {
          Swal.fire({
              icon: 'success',
              title: 'Data Added successfully!',
              showConfirmButton: false,
              timer: 2500
          })
          setIsModalOpen(false);
          const oldData = [...modiefiedData]

          response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

          const dataAdd = {
            record_id: response.data.record_id,
            room: response.data.room,
            lead_time: response.data.lead_time,
            remark: response.data.remark,
            updated_by: user,
            updated_timestamp: response.data.updated_timestamp
          }
          //Add new data to first line
          oldData.unshift(dataAdd)
          setLoading(true);
          setGridData(oldData)
          setLoading(false);
          setInputData({room: "", lead_time: "", remark:"",updated_by: user,});
        })
        .catch(function (error) {
          if(error.response.status === 401){
            Swal.fire("Token Expired", error.message, "error");
            setLoading(false);
            sessionStorage.clear();
            window.location.href = "/";
          }else{
            Swal.fire("Error", error.message, "error");
            setLoading(false);
          }
        });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInputData({room: "", lead_time: "", remark:"",updated_by: user,});
  };

  const loadData = async () => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
            ,
            params: {
                'room': roomInput,
            }
      };
    try{ 
      setLoading(true);
      const response = await axios.get(baseUrl+ apiUrl,config);

      for (var i=0; i< response.data.length; i++) {
        response.data[i].updated_timestamp = new Date(response.data[i].updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })
      }
      setGridData(response.data)
      setLoading(false);
      setCsvDisabled(false);
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
  }
  
  const reset = async () => {
    setGridData([]);
    setLoading(false);
    setRoomInput("");
    setCsvDisabled(true);
    setInputData({room:"",
        lead_time:"",
        remark:"",
        updated_by: user,
        // updated_timestamp:"",
      })
  }
  //Edit Data
  const isEditing = (record) => {
    return record.key === editRowKey;
  }

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (key) => {
    const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
      }; 
    try{
        const row = await form.validateFields();
        
        const newData = [...modiefiedData];
        const index = newData.findIndex((item) => key === item.key);

        var updatedTimestamp = new Date();
        row.updated_timestamp = updatedTimestamp

        const editedData = {
          record_id: newData[index].record_id,
          room: row.room,
          lead_time: parseInt(row.lead_time),
          remark: row.remark,
          updated_by: user,
          updated_timestamp: row.updated_timestamp
        }

        axios.put(baseUrl + apiUrl + `/update`,editedData,config)
        .then(function (response) {
            Swal.fire({
              icon: 'success',
              title: 'Data updated succesfully',
              showConfirmButton: false,
              timer: 1500
            })

            response.data.updated_timestamp = new Date(response.data.updated_timestamp).toLocaleString('sv-SE', { timeZone: tzName })

            if(index > -1){
              const dataEdited = {
                record_id: response.data.record_id,
                room: response.data.room,
                lead_time: response.data.lead_time,
                remark: response.data.remark,
                updated_by: user,
                updated_timestamp: response.data.updated_timestamp
              }
              newData.splice(index, 1, dataEdited);
              setGridData(newData);
            }
        })
    }catch(error){
      if(error.response.status === 401){
        sessionStorage.clear();
        window.location.href = "/";
        setLoading(false);
      }else{
        Swal.fire("Error", error.message, "error");
        setLoading(false);
      }
    }
    setEditRowKey("");
  }

  const edit = (record) => {
      form.setFieldsValue({
        record_id: "",
        room: "",
        lead_time: "",
        remark: "",
        ...record
      });
      setEditRowKey(record.key)
  }

  const modiefiedData = gridData.map(({ body, ...item}) => ({
    ...item,
    key: item.record_id,
    room: item.room,
    lead_time: parseInt(item.lead_time),
    remark: item.remark,
    updated_by: item.updated_by,
    updated_timestamp: item.updated_timestamp,
    message: isEmpty(body) ? item.message : body,
  }));
    //End Edit Data
  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
      align: "center",
      width: 50,
      hidden: true,
    },
    {
      title: "Room",
      dataIndex: "room",
      align: "center",
      editTable: true,
      width: 80,
    },
    {
      title: "Lead Time",
      dataIndex: "lead_time",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "center",
      editTable: true,
      width: 50,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      align: "center",
      // editTable: true,
      width: 50,
    },
    {
      title: "Updated Timestamp",
      dataIndex: "updated_timestamp",
      align: "center",
      // editTable: true,
      width: 80,
    },
    {
          title: 'Action',
          key: 'action',
          align: 'center',
          // fixed: 'right',
          width: 100,
          render: (_, record) => {
           const editable = isEditing(record);
           return modiefiedData.length >= 1 ? ( 
              <Space>
                  <Button onClick={() => handleDelete(record.record_id)} danger type='primary' disabled={!editData || editable}>
                    <DeleteOutlined/>
                  </Button>
                {editable ? (
                    <span>
                        <Space size="middle">
                          <Button 
                          onClick={() => save(record.key)} 
                          type="primary" 
                          style={{marginRight: 1}}
                          >
                            Save
                          </Button>
                          <Button onClick={cancel} >Cancel</Button>
                        </Space>
                    </span>
                ) : (
                  <Button onClick={() => edit(record)} type='primary' disabled={!editData}> 
                    <EditOutlined/>
                  </Button>
                )}
              </Space>
          ) : null;
        },
      },
    ].filter(item => !item.hidden);

  const handleDelete = (value) => {
    const config = {
      headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: 'You are deleting these items.',
      text: "Do you want to commit the operation?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.isConfirmed) {
          axios.delete(baseUrl + apiUrl + `/delete?record_id=`+value,config)
          .then(function (response) {
              Swal.fire({
                  icon: 'success',
                  title: 'Data deleted successfully!',
                  showConfirmButton: false,
                  timer: 1500
              })
              loadData();
          })
          .catch(function (error) {
            if(error.response.status === 401){
              sessionStorage.clear();
              window.location.href = "/";
            }else{
              Swal.fire("Error", error.message, "error");
            }
          });
      }
    })
  }

  const mergedColumns = columns.map((col) => {
      if(!col.editTable){
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
  });

  const EditableCell = ({
    editing, 
    dataIndex, 
    title, 
    record, 
    children, 
    ...restProps
  }) => {
    const input = <Input/>;

    return (
      <td {...restProps}>
          {editing ? (
            dataIndex === "remark" ? (
              <Form.Item 
                name={dataIndex} 
                style={{margin: 0 }}
                rules={
                [
                  {
                    required: false,
                    message: `Please input value ${title} field`,
                  },
                ]}
              >
               {input}
              </Form.Item>
             ) 
             : dataIndex === "room" ? (
              <Form.Item 
                name={dataIndex}
                style={{margin: 0 }}
              >
                <Input 
                list="roomList"
                onClick={clear}
                />
              </Form.Item>
               ) 
               : (
              <Form.Item 
                name={dataIndex} 
                style={{margin: 0 }}
                rules={
                [
                  {
                    required: true,
                    message: `Please input value ${title} field`,
                  },
                ]}
              >
               {input}
              </Form.Item>
             )
          ) : (
            children
          )}
      </td>
    );
  }

  const handleDownloadCSV = () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },params : {
            'room': roomInput,
            'timezone': tzName
        }
   }; 
    axios.get(baseUrl + apiUrl +"/csv", config)
    .then(response => { 
            const csvData = new Blob([response.data], { type: 'text/csv;charset="utf-8";' });
            FileSaver.saveAs(csvData, 'MASTER_PICKING_LEAD_TIME.csv');
        }).catch(error => {
            //Handle errors
            console.error(error);
        })
  }

  //API Combobox
  const getRoomCombobox = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      }; 
    const res = await axios.get(baseUrl+`/api/room/roomCombobox`,config);
    const dt = res.data;
    setRoomCombobox(dt);
  }

  //edit_data Permission
  const [editData, setEditData] = useState(false);
  const getEditDataPermission = async () => {
      const config = {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
        }; 
      try{
        const response = await fetch(
          baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json()); 
        // update the state
        const editDataPermission = response.permission;
        for(let i = 0 ; i < editDataPermission.length ;  i++){
          if(editDataPermission[i].module_name === "Picking Lead Time"){
              setEditData(editDataPermission[i].edit_data);
          }
        }
      }catch(error){
        sessionStorage.clear();
        window.location.href = "/";
      }
    }

    const clear = (event) => {
      event.target.value = "";
    };

  return (
    <div>
      <h2 style={{marginLeft:'10px'}}>Picking Lead Time</h2>
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <div>
            <span style={{margin: '6px'}}>Room : </span>
            <Input style={{margin:'5px'}} placeholder="" 
            value={roomInput}
            onChange={(e) => setRoomInput(e.target.value)}
            onClick={clear}
            list="roomList"
            />
            <datalist id="roomList">
                {roomCombobox.map((o) => (
                    <option key={o}>{o}</option>
                ))}
            </datalist>
          </div>
        </Col>
        
        <Col className="gutter-row" span={12}>
          <div>
            <Button onClick={() => loadData()} style={{marginLeft:'5px'}} type="primary">Search</Button>
            <Button onClick={() => handleDownloadCSV()} disabled={csvDisabled || gridData.length === 0} style={{marginLeft:'5px'}} type="primary">Download CSV</Button>
            <Button onClick={() => reset()} style={{marginLeft:'5px'}} type="primary">Reset</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Button onClick={showAddDataModal} style={{margin:'5px'}} disabled={!editData}>Add</Button>
            {/* Modal Add Data */}
            <Modal title="Add Picking Lead Time Data" centered open={isModalOpen} 
            okText="Add Data" 
            onOk={handleOk} 
            onCancel={handleCancel}
            destroyOnClose
            >
              <Form form={form}>
                <span>Room : </span>
                <Input value={inputData.room}
                  onChange={e => setInputData({...inputData, room: e.target.value})}
                  onClick={clear}
                  list="roomList"
                />
                <datalist id="roomList">
                  {roomCombobox.map((o) => (
                      <option key={o}>{o}</option>
                  ))}
                </datalist>
                <span>Lead Time : </span>
                <Input value={inputData.lead_time}
                  onChange={e => setInputData({...inputData, lead_time: e.target.value})}/>
                <span>Remark : </span>
                <Input value={inputData.remark}
                  onChange={e => setInputData({...inputData, remark: e.target.value})}/>
                <span>Updated By : </span>
                <Input value={user} disabled/>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              }
            }}
            dataSource={modiefiedData}
            loading={loading}
            bordered
            style={{ height: '300px' }}
            scroll={{
              x: 500,
              y: 300
            }}
            current
            pagination={{ 
              simple: true,
              pageSize: 50 ,
              total: modiefiedData.length, 
              showTotal: (total,range) => `Displaying ${range[0]} - ${range[1]} of ${total}`,
            }}
        />
      </Form>
    </div>
  )
}

export default MasterPickingLeadTime