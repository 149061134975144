import React, { useState , useEffect } from 'react'
import { environment } from '../../environment/environment';
import { Menu } from 'antd';
import { PoweroffOutlined } from "@ant-design/icons/lib/icons"
import {useNavigate } from 'react-router-dom'

const baseUrl = environment.apiUrl;
const token = sessionStorage.getItem('accessToken');

const Sidebar = () => {
    const navigate = useNavigate();

    const [viewMenuSaleTarget, setViewMenuSaleTarget] = useState(false);
    const [viewMenuCalendar, setViewMenuCalendar] = useState(false);
    const [viewMenuCalendarPeriod, setViewMenuCalendarPeriod] = useState(false);
    const [viewMenuPickingLeadTime, setViewMenuPickingLeadTime] = useState(false);
    const [viewMenuShippingLeadTime, setViewMenuShippingLeadTime] = useState(false);
    const [viewMenuDeliveryLeadTime, setViewMenuDeliveryLeadTime] = useState(false);
    const [viewMenuPostcode, setViewMenuPostcode] = useState(false);
    const [viewMenuReturnReason, setViewMenuReturnReason] = useState(false);
    const [viewMenuIncomingDelivery, setViewMenuIncomingDelivery] = useState(false);

  const getViewDataPermission = async () => {
    const config = {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      };
      try{
        const response = await fetch(
            baseUrl+"/api/auth/get-permission",config
        ).then((response) => response.json());
        // update the state

        const viewDataPermission = response.permission;
        
        for(let i = 0 ; i < viewDataPermission.length ;  i++){
          if(viewDataPermission[i].module_name === "Sales Target"){
            setViewMenuSaleTarget(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Calendar"){
            setViewMenuCalendar(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Calendar Period"){
            setViewMenuCalendarPeriod(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Picking Lead Time"){
            setViewMenuPickingLeadTime(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Shipping Lead Time"){
            setViewMenuShippingLeadTime(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Delivery Lead Time"){
            setViewMenuDeliveryLeadTime(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Postcode"){
            setViewMenuPostcode(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Return Reason"){
            setViewMenuReturnReason(viewDataPermission[i].view_data);
          }else if(viewDataPermission[i].module_name === "Incoming Delivery"){
            setViewMenuIncomingDelivery(viewDataPermission[i].view_data);
          }
        }
    }catch(error){
        sessionStorage.clear();
        window.location.href = "/";
    }
  }

  useEffect(() => {
      getViewDataPermission();
  },[]);

    const items = [
        viewMenuSaleTarget && 
        {
          label: 'Sales Target',
          key: '/master-data/master-sales-target',
        },
        viewMenuCalendar && {
          label: 'Calendar',
          key: '/master-data/master-calendar',
        },
        viewMenuCalendarPeriod && {
          label: 'Calendar Period',
          key: '/master-data/master-calendar-period',
        },
        viewMenuPickingLeadTime && {
          label: 'Picking Lead Time',
          key: '/master-data/master-picking-lead-time',
        },
        viewMenuShippingLeadTime && {
          label: 'Shipping Lead Time',
          key: '/master-data/master-shipping-lead-time',
        },
        viewMenuDeliveryLeadTime && {
          label: 'Delivery Lead Time',
          key: '/master-data/master-delivery-lead-time',
        },
        viewMenuPostcode && {
          label: 'Postcode',
          key: '/master-data/master-postcode',
        },
        viewMenuReturnReason && {
          label: 'Return Reason',
          key: '/master-data/master-return-reason',
        },
        viewMenuIncomingDelivery && {
          label: 'Incoming Delivery',
          key: '/warehouse/incoming-delivery',
        },
        {
          label: 'Sign out',
          key: 'signout',
          icon: <PoweroffOutlined />,
          danger: true,
        },
      ];

      
    const handleLogout = () => {
        sessionStorage.clear();
        window.location.href = "/";
    };

  return (
    <Menu
          onClick={({ key }) => {
            if(key === "signout"){
              handleLogout();
            }else{
              navigate(key);
            }
          }}
            defaultSelectedKeys={[window.location.pathname]}
            items={items}
            style={{backgroundColor : "lightgray " ,}}
    ></Menu>
  )
}

export default Sidebar