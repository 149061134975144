import './App.css';
import React from 'react'
import {Routes, Route } from 'react-router-dom'
import MasterCalendar from './components/Master/Calendar/Calendar';
import MasterCalendarPeriod from './components/Master/CalendarPeriod/CalendarPeriod';
import MasterPostcode from './components/Master/Postcode/Postcode';
import MasterReturnReason from './components/Master/ReturnReason/ReturnReason';
import IncomingDelivery from './components/Warehouse/IncomingDelivery';
import MasterDeliveryLeadTime from './components/Master/DeliveryLeadTime/DeliveryLeadTime';
import MasterShippingLeadTime from './components/Master/ShippingLeadTime/ShippingLeadTime';
import MasterPickingLeadTime from './components/Master/PickingLeadTime/PickingLeadTime';
import MasterSalesTarget from './components/Master/SalesTarget/SalesTarget';
import SignIn from './components/SignIn/SignIn';
import Sidebar from './components/Base/Sidebar';

const token = sessionStorage.getItem('accessToken');
const user = JSON.parse(sessionStorage.getItem('username'));

function App() {

  if(!token) {
    return <SignIn/>
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100vh"}}>
      <Header />
      <div style={{ display: "flex", flexDirection: "row", flex: 1,}}>
        <Sidebar/>
        <Content />
      </div>
        {/* <Footer /> */}
    </div>
  );
}

function Header(){
  return (
    <div 
      style={{ height: 60,
               backgroundColor : "lightgray " , 
               color: "black",
               display: "flex" ,
               justifyContent: "right",
               alignItems: "center",
               maxWidth: "100%",
            }}
    >
      <span style={{marginRight: '20px'}}>User : {user}</span>
    </div>
  );
}

// function Footer(){
//   return (
//     <div 
//       style={{ height: 60,
//                backgroundColor : "light" , 
//                color: "white",
//                display: "flex" ,
//                justifyContent: "center",
//                alignItems: "center",
//                fontWeight: "bold",
//             }}
//     >
//       {/* Footer */}
//     </div>
//   );
// }

function Content(){
  return (
    <div >
      <Routes>
        <Route path="/home" element={<div></div>}></Route>
        <Route path="/master-data/master-sales-target" element={<MasterSalesTarget/>}></Route>
        <Route path="/master-data/master-calendar" element={<MasterCalendar/>}></Route> 
        <Route path="/master-data/master-calendar-period" element={<MasterCalendarPeriod/>}></Route>
        <Route path="/master-data/master-picking-lead-time" element={<MasterPickingLeadTime/>}></Route>
        <Route path="/master-data/master-shipping-lead-time" element={<MasterShippingLeadTime/>}></Route>
        <Route path="/master-data/master-delivery-lead-time" element={<MasterDeliveryLeadTime/>}></Route>
        <Route path="/master-data/master-postcode" element={<MasterPostcode/>}></Route>
        <Route path="/master-data/master-return-reason" element={<MasterReturnReason/>}></Route>
        <Route path="/warehouse/incoming-delivery" element={<IncomingDelivery/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
